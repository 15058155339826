<template>
<div class="s-frame">
    <a-layout>
        <a-layout-content>
            <a-row class="s-layout" style="padding-left:0;padding-right:0">
                <a-col :span="24" style="display:flex">
                    <div class="s-sidebar-div">
                        <div class="s-m-font1" style="margin-bottom:60px">나의 키워드</div>
                        <div style="display:flex">
                            <div>내그룹</div>
                            <div style="float:right;right:0;position:absolute;margin-right:30px">
                                <div style="display:flex;">
                                <!-- <div>그룹편집</div> -->
                                <!-- <div>추가</div> -->
                                </div>
                            </div>
                        </div>
                        <div style="margin-top:10px" v-if="viewNewGroupName">
                            <a-input class="s-input1" v-model="keyword" @pressEnter="onSearch('')" placeholder="그룹명을 검색해주세요">
                                <div slot="suffix">
                                    <img src="@/assets/images/v4-icon/search.png" />
                                </div>
                            </a-input>
                        </div>
                        <div v-for="item in groupname" :key="item.key">
                            <div>
                                
                            </div>
                        </div>


                    </div>
                    <div class="s-contents-div">
                        <div class="s-m-font2" style="padding-bottom:10px"><span>{{skeyword}}</span> 지정되지 않은 키워드</div>
                        <div class="s-m-font3" ><span class="date">{{cumdate}}</span>&nbsp;<span class="title">기준 데이터입니다.</span> </div>
                        <find-table :code="code" :sidebar="sidebar" :keyword="skeyword" :mode="SEARCH_TYPE.SIMPLE"></find-table>
                    </div>
                </a-col>
            </a-row>
        </a-layout-content>
    </a-layout>
</div>
</template>

<script>
import {
  getCurrentTimeNew,
  getToday,
  getHex,
  replaceAll,
  chkLicense,
  showError,
  showSuccess,
  isMobile,
  SEARCH_TYPE,
  SIDEMENU_TYPE
} from "../../components/fnc.js";
import firebase from "firebase";
import FindTable from "../../components/FindTable/index.vue";
export default {
  components: {
    FindTable
  },
  data() {
    return {
      SEARCH_TYPE: SEARCH_TYPE,
      SIDEMENU_TYPE: SIDEMENU_TYPE,
      code: "",
      cumdate: "",
      sidebar: "",
      viewNewGroupName: false,
      skeyword: "",
      keyword: "",
      loading: false
    };
  },
  methods: {},
  mounted() {
    this.sidebar = SIDEMENU_TYPE.MYKEYWORD;
    this.cumdate = getToday();
  }
};
</script>

<style>
.s-m-font1 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  letter-spacing: -0.03em;

  color: #000000;
}

.s-m-font2 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  letter-spacing: -0.03em;

  color: #000000;
}

.s-m-font3 {
  font-family: "Mulish", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.03em;

  color: #000000;
}
.s-m-font3 > .date {
  font-weight: bold;
}
</style>
